import { AppstoreFilled, AppstoreTwoTone, BuildTwoTone } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Input, Modal, Row, Table, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { all, create } from 'mathjs'
import { FC } from 'react'
import { useEstimateState } from 'widgets/estimate/model/estimate-state'
import { useShallow } from 'zustand/react/shallow'
import { EstimateImportDataApi, ImportFromExcel, ImportFromExcelWork, ImportMaterials } from '..'
const config = {}
const math = create(all, config)

interface IProps {
	res: any
	show: boolean
	registry?: 'wbsRegistry' | 'localEstimateRegistry'
	onClose: () => void
	isAdjustment?: boolean
	containerId: string
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing?: boolean
	dataIndex?: string
	title?: string
	record?: ImportMaterials | ImportFromExcel
	price?: number
	index?: number
	children?: React.ReactNode
}

const EditableCell: FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	record,
	price,
	children,
	...restProps
}) => {
	return (
		<td
			{...restProps}
			style={{
				textAlign: 'center',
				backgroundColor:
					(editing && 'children' in record! && 'workName' in record) ||
					(editing && dataIndex === 'totalSumServiceFile') ||
					(editing && 'materialName' in record! && dataIndex === 'priceMaterialFromFile')
						? '#FADADD'
						: ''
			}}
		>
			<div
				style={{ justifyContent: dataIndex === 'workName' ? 'start' : 'center', display: 'flex' }}
			>
				{children}
			</div>
		</td>
	)
}
export const EstimateImportModal: FC<IProps> = props => {
	const queryClient = useQueryClient()
	const { res, show, registry, onClose, isAdjustment, containerId } = props
	const { setExpandedRows, updateSections, container } = useEstimateState(
		useShallow(state => ({
			setExpandedRows: state.setExpandedRows,
			updateSections: state.updateSections,
			container: state.container
		}))
	)
	function replace(dataRes: any) {
		dataRes?.forEach((data: any) => {
			if (data.work?.length !== 0) {
				data.children = data.work.map((m: any) => ({
					...m,
					children: m.workName ? m.materials : []
				}))
			} else {
				replace(data.children!)
			}
		})
	}

	replace(res!)

	const importColumns: ColumnsType<ImportMaterials | ImportFromExcelWork | ImportFromExcel> = [
		{
			key: 'workName',
			dataIndex: 'workName',
			title: 'Позиция сметы',
			width: 30,
			fixed: 'left',
			align: 'left',
			render: (_, record) => (
				<Row
					justify="space-between"
					align="middle"
					wrap={false}
					style={{
						width: '100%',
						height: '100%'
					}}
				>
					{'materialName' in record ? (
						'materialName' in record && (
							<div style={{ textAlign: 'start' }}>{record.materialName}</div>
						)
					) : 'codifier' in record ? (
						<div style={{ textAlign: 'start' }}>{`${record.codifier} ${record.name}`}</div>
					) : (
						'workName' in record && <div style={{ textAlign: 'start' }}>{record.workName}</div>
					)}
				</Row>
			),
			onCell: record => ({
				record,
				price: 0,
				dataIndex: 'workName',
				title: 'Позиция сметы',
				editing: false
			})
		},
		{
			key: 'prices',
			title: 'Цена (материалы)',
			align: 'center',
			children: [
				{
					key: 'priceMaterial',
					dataIndex: 'priceMaterialFromDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center'
				},
				{
					key: 'priceService',
					dataIndex: 'priceMaterialFromFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'priceMaterialFromFile' in record
							? 'priceMaterialFromFile' in record && math.round(record!?.priceMaterialFromFile!, 2)
							: '',
					onCell: record => ({
						record,
						price:
							'priceMaterialDeviation' in record && math.round(record!?.priceMaterialFromFile!, 2),
						dataIndex: 'priceMaterialFromFile',
						title: 'Excel',
						editing: 'priceMaterialDeviation' in record && record!?.priceMaterialDeviation! !== 0
					})
				},
				{
					key: 'price',
					dataIndex: 'priceMaterialDeviation',
					title: 'Отклонение',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'priceMaterialDeviation' in record
							? 'priceMaterialDeviation' in record &&
							  math.round(record!?.priceMaterialDeviation!, 2)
							: ''
				}
			]
		},
		{
			key: 'sum',
			title: 'Цена (СМР/услуги)',
			align: 'center',
			children: [
				{
					dataIndex: 'totalSumServiceDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'workName' in record
							? 'priceServiceFromDb' in record && record!?.priceServiceFromDb!
							: ''
				},
				{
					dataIndex: 'totalSumServiceFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'workName' in record
							? 'priceServiceFromFile' in record && math.round(record!?.priceServiceFromFile!, 2)
							: '',
					onCell: record => ({
						record,
						price:
							'workName' in record &&
							'priceServiceFromFile' in record &&
							record!?.priceServiceFromFile!,
						dataIndex: 'totalSumServiceFile',
						title: 'Excel',
						editing: 'priceServiceFromFile' in record && record!?.priceServiceDeviation! !== 0
					})
				},
				{
					dataIndex: 'totalSumServiceDeviation',
					title: 'Отклонение',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'workName' in record
							? 'priceServiceDeviation' in record && math.round(record!?.priceServiceDeviation!, 2)
							: ''
				}
			]
		},
		{
			key: 'notesMaterial',
			title: 'Примечания СДУ',
			align: 'center',
			hidden: registry === 'wbsRegistry' ? false : true,
			children: [
				{
					key: 'noteSduFromDb',
					dataIndex: 'noteSduFromDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'noteSduFromDb' in record &&
						record!?.noteSduFromDb!?.length! && (
							<Input.TextArea
								autoSize={false}
								style={{ background: 'transparent', color: '#444', resize: 'none' }}
								onClick={e => {
									e.currentTarget.blur()
									e.preventDefault()
									e.stopPropagation()
								}}
								value={record!?.noteSduFromDb!}
							/>
						)
				},
				{
					key: 'noteSduFromFile',
					dataIndex: 'noteSduFromFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'noteSduFromFile' in record &&
						record!?.noteSduFromFile!?.length! && (
							<Input.TextArea
								autoSize={false}
								style={{ background: 'transparent', color: '#444', resize: 'none' }}
								onClick={e => {
									e.currentTarget.blur()
									e.preventDefault()
									e.stopPropagation()
								}}
								value={record!?.noteSduFromFile!}
							/>
						)
				}
			]
		},
		{
			key: 'notesMaterial',
			title: 'Примечания ДЗ',
			align: 'center',
			hidden: registry === 'wbsRegistry' ? false : true,
			children: [
				{
					key: 'noteDzFromDb',
					dataIndex: 'noteDzFromDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'noteDzFromDb' in record &&
						record!?.noteDzFromDb!?.length! && (
							<Input.TextArea
								autoSize={false}
								style={{ background: 'transparent', color: '#444', resize: 'none' }}
								onClick={e => {
									e.currentTarget.blur()
									e.preventDefault()
									e.stopPropagation()
								}}
								value={record!?.noteDzFromDb!}
							/>
						)
				},
				{
					key: 'noteDzFromFile',
					dataIndex: 'noteDzFromFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'noteDzFromFile' in record &&
						record!?.noteDzFromFile!?.length! && (
							<Input.TextArea
								autoSize={false}
								style={{ background: 'transparent', color: '#444', resize: 'none' }}
								onClick={e => {
									e.currentTarget.blur()
									e.preventDefault()
									e.stopPropagation()
								}}
								value={record!?.noteDzFromFile!}
							/>
						)
				}
			]
		},
		{
			key: 'notesMaterial',
			title: 'Примечания ДП',
			align: 'center',
			hidden: registry === 'wbsRegistry' ? false : true,
			children: [
				{
					key: 'noteDpFromDb',
					dataIndex: 'noteDpFromDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'noteDpFromDb' in record &&
						record!?.noteDpFromDb!?.length! && (
							<Input.TextArea
								autoSize={false}
								style={{ background: 'transparent', color: '#444', resize: 'none' }}
								onClick={e => {
									e.currentTarget.blur()
									e.preventDefault()
									e.stopPropagation()
								}}
								value={record!?.noteDpFromDb!}
							/>
						)
				},
				{
					key: 'noteDpFromFile',
					dataIndex: 'noteDpFromFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'noteDpFromFile' in record &&
						record!?.noteDpFromFile!?.length! && (
							<Input.TextArea
								autoSize={false}
								style={{ background: 'transparent', color: '#444', resize: 'none' }}
								onClick={e => {
									e.currentTarget.blur()
									e.preventDefault()
									e.stopPropagation()
								}}
								value={record!?.noteDpFromFile!}
							/>
						)
				}
			]
		}
	]

	const importAdjustmentColumns: ColumnsType<
		ImportMaterials | ImportFromExcelWork | ImportFromExcel
	> = [
		{
			key: 'workName',
			dataIndex: 'workName',
			title: 'Позиция сметы',
			width: 30,
			fixed: 'left',
			align: 'left',
			render: (_, record) => (
				<Row
					justify="space-between"
					align="middle"
					wrap={false}
					style={{
						width: '100%',
						height: '100%'
					}}
				>
					{'materialName' in record ? (
						'materialName' in record && (
							<div style={{ textAlign: 'start' }}>{record.materialName}</div>
						)
					) : 'codifier' in record ? (
						<div style={{ textAlign: 'start' }}>{`${record.codifier} ${record.name}`}</div>
					) : (
						'workName' in record && <div style={{ textAlign: 'start' }}>{record.workName}</div>
					)}
				</Row>
			),
			onCell: record => ({
				record,
				price: 0,
				dataIndex: 'workName',
				title: 'Позиция сметы',
				editing: false
			})
		},
		{
			key: 'prices',
			title: 'Цена (материалы)',
			align: 'center',
			children: [
				{
					key: 'priceMaterial',
					dataIndex: 'priceMaterialFromDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center'
				},
				{
					key: 'priceService',
					dataIndex: 'priceMaterialFromFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'priceMaterialFromFile' in record
							? 'priceMaterialFromFile' in record && math.round(record!?.priceMaterialFromFile!, 2)
							: '',
					onCell: record => ({
						record,
						price:
							'priceMaterialDeviation' in record && math.round(record!?.priceMaterialFromFile!, 2),
						dataIndex: 'priceMaterialFromFile',
						title: 'Excel',
						editing: 'priceMaterialDeviation' in record && record!?.priceMaterialDeviation! !== 0
					})
				},
				{
					key: 'price',
					dataIndex: 'priceMaterialDeviation',
					title: 'Отклонение',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'priceMaterialDeviation' in record
							? 'priceMaterialDeviation' in record &&
							  math.round(record!?.priceMaterialDeviation!, 2)
							: ''
				}
			]
		},
		{
			key: 'sum',
			title: 'Цена (СМР/услуги)',
			align: 'center',
			children: [
				{
					dataIndex: 'totalSumServiceDb',
					title: 'Документ ВОР',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'workName' in record
							? 'priceServiceFromDb' in record && record!?.priceServiceFromDb!
							: ''
				},
				{
					dataIndex: 'totalSumServiceFile',
					title: 'Excel',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'workName' in record
							? 'priceServiceFromFile' in record && math.round(record!?.priceServiceFromFile!, 2)
							: '',
					onCell: record => ({
						record,
						price:
							'workName' in record &&
							'priceServiceFromFile' in record &&
							record!?.priceServiceFromFile!,
						dataIndex: 'totalSumServiceFile',
						title: 'Excel',
						editing: 'priceServiceFromFile' in record && record!?.priceServiceDeviation! !== 0
					})
				},
				{
					dataIndex: 'totalSumServiceDeviation',
					title: 'Отклонение',
					width: 10,
					align: 'center',
					render: (_, record) =>
						'workName' in record
							? 'priceServiceDeviation' in record && math.round(record!?.priceServiceDeviation!, 2)
							: ''
				}
			]
		}
	]

	const updatedResArray: ImportFromExcel[] = []
	const updated = (resData: ImportFromExcel[]) => {
		for (const data of resData) {
			let updatedRes: any = {
				id: data.id,
				noteDpFromFile: data?.noteDpFromFile,
				noteDzFromFile: data?.noteDzFromFile,
				noteSduFromFile: data?.noteSduFromFile,
				priceMaterialFromFile: data?.priceMaterialFromFile,
				priceServiceFromFile: data?.priceServiceFromFile,
				materials: data.materials?.map(m => ({
					id: m.id,
					noteDpFromFile: m?.noteDpFromFile,
					noteDzFromFile: m?.noteDzFromFile,
					noteSduFromFile: m?.noteSduFromFile,
					priceMaterialFromFile: m?.priceMaterialFromFile
				}))
			}
			if (data.workName) {
				updatedRes = {
					id: data.id,
					noteDpFromFile: data?.noteDpFromFile,
					noteDzFromFile: data?.noteDzFromFile,
					noteSduFromFile: data?.noteSduFromFile,
					priceMaterialFromFile: data?.priceMaterialFromFile,
					priceServiceFromFile: data?.priceServiceFromFile,
					materials: data.materials?.map(m => ({
						id: m.id,
						noteDpFromFile: m?.noteDpFromFile,
						noteDzFromFile: m?.noteDzFromFile,
						noteSduFromFile: m?.noteSduFromFile,
						priceMaterialFromFile: m?.priceMaterialFromFile
					}))
				}
			} else if (data.children !== undefined) {
				updated(data.children!)
			}

			updatedResArray.push(updatedRes)
		}
	}

	const updatedAdjustment = (resData: ImportFromExcel[]) => {
		for (const data of resData) {
			let updatedRes: any = {
				id: data?.id,
				priceMaterialFromFile: data?.priceMaterialFromFile,
				priceServiceFromFile: data?.priceServiceFromFile,
				materials: data.materials?.map(m => ({
					id: m?.id,
					priceMaterialFromFile: m?.priceMaterialFromFile
				}))
			}
			if (data.workName) {
				updatedRes = {
					id: data?.id,
					priceMaterialFromFile: data?.priceMaterialFromFile,
					priceServiceFromFile: data?.priceServiceFromFile,
					materials: data.materials?.map(m => ({
						id: m?.id,
						priceMaterialFromFile: m?.priceMaterialFromFile
					}))
				}
			} else if (data.children !== undefined) {
				updatedAdjustment(data.children!)
			}
			updatedResArray.push(updatedRes)
		}
	}

	const updateEstimate = async () => {
		isAdjustment === true ? updatedAdjustment(res) : updated(res)
		await EstimateImportDataApi.confirm(
			isAdjustment === true
				? { adjustmentId: containerId!, previewWorks: updatedResArray }
				: updatedResArray,
			registry === 'wbsRegistry' ? 'wbs' : 'local',
			isAdjustment
		)
			.then(data => {
				updateSections(container?.typeSections.id!)
				message.success('Данные успешно обновлены')
				console.log('data', data)
			})
			.then(() => {
				registry
					? queryClient.invalidateQueries({ queryKey: [registry] })
					: window.location.reload()
				onClose()
			})
			.catch(err => {
				message.error('Произошла ошибка во время импорта')
				console.log('err', err)
			})
	}

	return (
		<Modal
			open={show}
			onCancel={onClose}
			destroyOnClose={true}
			width={window.innerWidth - (window.innerWidth * 10) / 100}
			title="Сравнение цен при загрузке из Excel"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button onClick={onClose}>Отмена</Button>
					</Col>
					<Col>
						<Button
							data-attr="buttonLoadingComparePrice"
							type="primary"
							onClick={() => {
								updateEstimate()
								queryClient.invalidateQueries({ queryKey: ['estimate'] })
								setExpandedRows({})
							}}
						>
							Загрузить
						</Button>
					</Col>
				</Row>
			}
		>
			<Table
				sticky={{ offsetHeader: 0 }}
				components={{
					body: {
						cell: EditableCell
					}
				}}
				className="app-wbs-table"
				tableLayout="fixed"
				rowKey={row => row.id}
				size="small"
				bordered
				dataSource={res!}
				columns={isAdjustment ? importAdjustmentColumns : importColumns}
				scroll={{ y: (window.outerHeight * 50) / 55 }}
				onRow={row => ({
					style: { cursor: 'workName' in row ? 'pointer' : 'default' }
				})}
				expandable={{
					expandRowByClick: true,
					expandIcon: ({ expanded, onExpand, record }) =>
						'children' in record ? (
							'children' in record && !!record.children?.length ? (
								expanded ? (
									<AppstoreTwoTone twoToneColor="#6fa9fa" onClick={e => onExpand(record, e)} />
								) : (
									<AppstoreFilled style={{ color: '#6fa9fa' }} onClick={e => onExpand(record, e)} />
								)
							) : (
								<AppstoreTwoTone twoToneColor="#9e9e9e" />
							)
						) : (
							<BuildTwoTone style={{ marginLeft: '30px' }} twoToneColor="#5d9f6c" />
						)
				}}
				pagination={false}
			/>
		</Modal>
	)
}
