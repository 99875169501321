import { API } from 'services/ApiService'
import { ImportFromExcel } from '..'

export const EstimateImportDataApi = {
	async getPrepData(file: FormData, containerId: string, type: 'wbs' | 'local', isAdjustment?: boolean) {
		const urlWbs = isAdjustment === true ? 'adjustment/previewContractorVersionOfImportingFile' : 'containers/previewImportFile'
		const urlEstimate = isAdjustment === true ? 'adjustment/previewContractorVersionOfImportingFile' : 'containerEstimates/previewImportFile'
		return await API({
			url: `/v1/${type === 'wbs' ? urlWbs : urlEstimate}`,
			method: 'post',
			params:  isAdjustment === true ? {adjustmentId : containerId} : {containerId} ,
			data: file
		}).then(async response => response.data)
	},
	async confirm(data: ImportFromExcel[] | {adjustmentId: string, previewWorks: ImportFromExcel[]}, type: 'wbs' | 'local', isAdjustment?: boolean) {
		const urlWbs = isAdjustment === true ? 'adjustment/workFromAdjustmentContractorVersion' : 'estimatePositions/workFromContainer'
		const urlEstimate = isAdjustment === true ? 'adjustment/workFromAdjustmentContractorVersion' : 'estimatePositions/workFromEstimate'
		return await API({
			url: `/v1/${type === 'wbs' ? urlWbs : urlEstimate}`,
			method: 'patch',
			data: data
		})
	}
}
